<script>
export default {
    name: "Airdrop"
}
</script>

<template>
    <section class="airdrop">
        soon...
    </section>
</template>

<style scoped>
.airdrop {
    width: 100%;
    height: 100vh;
    background: #000;

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 50px;
}
</style>