<template>
    <section class="home">
        <section class="user-info">
            <div class="user-info-container">
                <div class="user-info-left-container">
                    <!-- Скелетон для аватара -->
                    <div v-if="loading" class="skeleton skeleton-avatar"></div>
                    <img v-else :src="getAvatar" class="user-info-avatar data-element loaded" />

                    <div class="user-info-about-container">
                        <!-- Скелетоны для имени и статуса -->
                        <div v-if="loading" class="skeleton skeleton-text"></div>
                        <div v-else class="user-info-name data-element loaded">{{ getNickname }}</div>

                        <div v-if="loading" class="skeleton skeleton-text"></div>
                        <div v-else class="user-info-status data-element loaded">
                            <img class="bear-emoji" src="@/assets/icons/bear.png" />{{ getStatus }}
                        </div>
                    </div>
                </div>
                <div class="user-info-right-container">
                    <div class="get-top-button">
                        <img class="trophy-emoji" src="@/assets/icons/trophy.png" />
                    </div>
                </div>
            </div>
        </section>

        <section class="farming">
            <!-- Скелетон для баланса -->
            <div v-if="loading" class="skeleton skeleton-balance"></div>
            <div v-else class="balance data-element loaded">
                <img class="pixely-balance" src="@/assets/logo.svg" />{{ getBalance }}
            </div>
        </section>
    </section>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
    name: "Home",
    components: { Navbar },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        getNickname() {
            return this.$store.state.nickname;
        },
        getBalance() {
            return this.$store.state.balance;
        },
        getAvatar() {
            return this.$store.state.avatar;
        },
        getStatus() {
            return this.$store.state.status;
        },
    },
    mounted() {
        setTimeout(() => {
            this.loading = false; // Данные загружены
        }, 1000);
    },
};
</script>

<style scoped>

    .skeleton {
        background-color: #000000;
        background-image: linear-gradient(90deg, #0D0D0D 25%, #171717 50%, #0D0D0D 75%);
        background-size: 200% 100%;
        animation: skeleton-wave 1.5s infinite ease-in-out;
        border-radius: 4px;
        transition: opacity 0.5s ease 0.2s;  /* Плавный переход для скрытия скелетонов */
    }

    @keyframes skeleton-wave {
        0% {
            background-position: 200% 0;
        }
        100% {
            background-position: -200% 0;
        }
    }

    .skeleton-avatar {
        border-radius: 50%;
        width: 42px;
        height: 42px;
    }

    .skeleton-text {
        width: 100px;
        height: 15px;
        margin-top: 5px;
        border-radius: 5px;
    }

    .skeleton-balance {
        width: 200px;
        height: 44px;
        border-radius: 10px;
    }

    /* Плавный переход для элементов данных */
    .data-element {
        opacity: 0; /* Начальное состояние - элемент скрыт */
        transition: opacity 0.5s ease; /* Плавное появление */
    }

    .data-element.loaded {
        opacity: 1; /* Когда данные загружены, элемент становится видимым */
    }

    .home {
        background: #000;
        width: 100%;
        height: 100vh;
    }

    .user-info {
        width: 100%;
        height: 133px;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: #000000;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url(../assets/images/user-info.png);
    }

    .user-info-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        backdrop-filter: blur(54px);
        background: rgba(255, 250, 250, 0.1);
    }

    .user-info-left-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .user-info-name {
        font-weight: 700;
        color: #fff;
    }

    .user-info-about-container {
        display: flex;
        flex-direction: column;
    }

    .user-info-status {
        display: flex;
        align-items: center;
        justify-content: center;

        font-weight: 600;
        color: #e0e0e0;
    }

    .get-top-button {
        display: flex;
        align-items: center;
        justify-content: center;

        background: rgba(149, 149, 149, 0.24);
        border: 1px solid rgba(217, 217, 217, 0.24);
    }

    .farming {
        width: 100%;
        height: 150px;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: #000000;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url(../assets/images/balance.png);
    }

    .balance {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        font-weight: 700;
        color: #fff;
    }

    @media (min-width: 316px) {
        .user-info {
            height: 133px;
        }

        .user-info-container {
            border-radius: 100px;
            width: 316px;
            height: 60px;
        }

        .user-info-avatar {
            height: 42px;
            width: 42px;
            border-radius: 100px;
        }

        .user-info-left-container {
            margin-left: 20px;
            gap: 10px;
        }

        .user-info-name {
            font-size: 15px;
        }

        .user-info-about-container {
            gap: 5px;
        }

        .user-info-status {
            gap: 4px;
            font-size: 14px;
        }

        .bear-emoji {
            height: 18px;
            width: 18px;
        }

        .user-info-right-container {
            margin-right: 20px;
        }

        .get-top-button {
            border-radius: 15px;
            width: 42px;
            height: 42px;
        }

        .trophy-emoji {
            height: 20px;
        }

        .balance {
            font-size: 44px;
            gap: 12px;
        }

        .pixely-balance {
            height: 66px;
        }
    }
</style>