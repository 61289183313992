import { createStore } from 'vuex'

const store = createStore({
  state: {
    avatar: 'https://i.imgur.com/vkEvWDT.png',
    nickname: 'pixely',
    status: 'Newbie',
    balance: '0,00',
  },
})

export default store
