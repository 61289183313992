<template>
    <section class="navbar">
        <nav class="navbar-container">
            <div
                v-for="(item, index) in navbarItems"
                :key="index"
                class="navbar-item"
                :class="{ active: route.name === item.page }"
                @click="navigateTo(item.page)"
            >
                <div
                    class="navbar-icon"
                    v-html="item.icon"
                ></div>
            <span>{{ item.name }}</span>
            </div>
        </nav>
    </section>
</template>

<script>
import { useRouter, useRoute } from 'vue-router';

export default {
    name: "Navbar",
    setup() {
        const router = useRouter();
        const route = useRoute();

        const navigateTo = (page) => {
            console.log(`Navigating to ${page}`);
            router.push({ name: page });
        };

        return {
            navigateTo,
            route,
        };
    },
    data() {
        return {
            navbarItems: [
                {
                    name: "Tasks",
                    icon: `
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 5V12L17.7 13.7C17.8 13.8 17.875 13.9125 17.925 14.0375C17.975 14.1625 18 14.2917 18 14.425V15C18 15.2833 17.9042 15.5208 17.7125 15.7125C17.5208 15.9042 17.2833 16 17 16H13V21.85C13 22.1333 12.9042 22.3708 12.7125 22.5625C12.5208 22.7542 12.2833 22.85 12 22.85C11.7167 22.85 11.4792 22.7542 11.2875 22.5625C11.0958 22.3708 11 22.1333 11 21.85V16H7C6.71667 16 6.47917 15.9042 6.2875 15.7125C6.09583 15.5208 6 15.2833 6 15V14.425C6 14.2917 6.025 14.1625 6.075 14.0375C6.125 13.9125 6.2 13.8 6.3 13.7L8 12V5C7.71667 5 7.47917 4.90417 7.2875 4.7125C7.09583 4.52083 7 4.28333 7 4C7 3.71667 7.09583 3.47917 7.2875 3.2875C7.47917 3.09583 7.71667 3 8 3H16C16.2833 3 16.5208 3.09583 16.7125 3.2875C16.9042 3.47917 17 3.71667 17 4C17 4.28333 16.9042 4.52083 16.7125 4.7125C16.5208 4.90417 16.2833 5 16 5Z" fill="currentColor"/>
                    </svg>
                    `,
                    page: "Tasks"
                },
                {
                    name: "Frens",
                    icon: `
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.90002 16.9C2.90002 16.4667 3.00419 16.0709 3.21252 15.7125C3.42086 15.3542 3.70836 15.0667 4.07502 14.85C4.97502 14.3167 5.92919 13.9084 6.93752 13.625C7.94586 13.3417 9.00002 13.2 10.1 13.2C11.2 13.2 12.2542 13.3417 13.2625 13.625C14.2709 13.9084 15.225 14.3167 16.125 14.85C16.4917 15.0667 16.7792 15.3542 16.9875 15.7125C17.1959 16.0709 17.3 16.4667 17.3 16.9V17.4C17.3 17.9 17.125 18.325 16.775 18.675C16.425 19.025 16 19.2 15.5 19.2H4.70002C4.20002 19.2 3.77502 19.025 3.42502 18.675C3.07502 18.325 2.90002 17.9 2.90002 17.4V16.9ZM18.625 19.2C18.775 18.9167 18.8917 18.625 18.975 18.325C19.0584 18.025 19.1 17.7167 19.1 17.4V16.9C19.1 16.2 18.9375 15.55 18.6125 14.95C18.2875 14.35 17.85 13.8584 17.3 13.475C17.95 13.6084 18.5792 13.7875 19.1875 14.0125C19.7959 14.2375 20.375 14.5167 20.925 14.85C21.2917 15.0667 21.5792 15.3542 21.7875 15.7125C21.9959 16.0709 22.1 16.4667 22.1 16.9V17.4C22.1 17.9 21.925 18.325 21.575 18.675C21.225 19.025 20.8 19.2 20.3 19.2H18.625ZM10.1 12C9.10002 12 8.25002 11.65 7.55002 10.95C6.85002 10.25 6.50002 9.40005 6.50002 8.40005C6.50002 7.40005 6.85002 6.55005 7.55002 5.85005C8.25002 5.15005 9.10002 4.80005 10.1 4.80005C11.1 4.80005 11.95 5.15005 12.65 5.85005C13.35 6.55005 13.7 7.40005 13.7 8.40005C13.7 9.40005 13.35 10.25 12.65 10.95C11.95 11.65 11.1 12 10.1 12ZM18.5 8.40005C18.5 9.40005 18.15 10.25 17.45 10.95C16.75 11.65 15.9 12 14.9 12C14.7667 12 14.6417 11.9959 14.525 11.9875C14.4084 11.9792 14.2834 11.9584 14.15 11.925C14.5667 11.4417 14.8959 10.9042 15.1375 10.3125C15.3792 9.72088 15.5 9.08338 15.5 8.40005C15.5 7.71672 15.3792 7.07922 15.1375 6.48755C14.8959 5.89588 14.5667 5.35838 14.15 4.87505C14.2834 4.84172 14.4084 4.82088 14.525 4.81255C14.6417 4.80422 14.7667 4.80005 14.9 4.80005C15.9 4.80005 16.75 5.15005 17.45 5.85005C18.15 6.55005 18.5 7.40005 18.5 8.40005Z" fill="currentColor"/>
                    </svg>

                    `,
                    page: "Frens"
                },
                {
                    name: "Home",
                    icon: `
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.5 19V10C4.5 9.68333 4.57083 9.38333 4.7125 9.1C4.85417 8.81667 5.05 8.58333 5.3 8.4L11.3 3.9C11.65 3.63333 12.05 3.5 12.5 3.5C12.95 3.5 13.35 3.63333 13.7 3.9L19.7 8.4C19.95 8.58333 20.1458 8.81667 20.2875 9.1C20.4292 9.38333 20.5 9.68333 20.5 10V19C20.5 19.55 20.3042 20.0208 19.9125 20.4125C19.5208 20.8042 19.05 21 18.5 21H15.5C15.2167 21 14.9792 20.9042 14.7875 20.7125C14.5958 20.5208 14.5 20.2833 14.5 20V15C14.5 14.7167 14.4042 14.4792 14.2125 14.2875C14.0208 14.0958 13.7833 14 13.5 14H11.5C11.2167 14 10.9792 14.0958 10.7875 14.2875C10.5958 14.4792 10.5 14.7167 10.5 15V20C10.5 20.2833 10.4042 20.5208 10.2125 20.7125C10.0208 20.9042 9.78333 21 9.5 21H6.5C5.95 21 5.47917 20.8042 5.0875 20.4125C4.69583 20.0208 4.5 19.55 4.5 19Z" fill="currentColor"/>
                    </svg>

                    `,
                    page: "Home"
                },
                {
                    name: "Boost",
                    icon: `
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 16.125V7.875C3 7.575 3.1 7.33333 3.3 7.15C3.5 6.96667 3.73333 6.875 4 6.875C4.08333 6.875 4.175 6.88333 4.275 6.9C4.375 6.91667 4.46667 6.95833 4.55 7.025L10.75 11.175C10.9 11.275 11.0125 11.3958 11.0875 11.5375C11.1625 11.6792 11.2 11.8333 11.2 12C11.2 12.1667 11.1625 12.3208 11.0875 12.4625C11.0125 12.6042 10.9 12.725 10.75 12.825L4.55 16.975C4.46667 17.0417 4.375 17.0833 4.275 17.1C4.175 17.1167 4.08333 17.125 4 17.125C3.73333 17.125 3.5 17.0333 3.3 16.85C3.1 16.6667 3 16.425 3 16.125ZM13 16.125V7.875C13 7.575 13.1 7.33333 13.3 7.15C13.5 6.96667 13.7333 6.875 14 6.875C14.0833 6.875 14.175 6.88333 14.275 6.9C14.375 6.91667 14.4667 6.95833 14.55 7.025L20.75 11.175C20.9 11.275 21.0125 11.3958 21.0875 11.5375C21.1625 11.6792 21.2 11.8333 21.2 12C21.2 12.1667 21.1625 12.3208 21.0875 12.4625C21.0125 12.6042 20.9 12.725 20.75 12.825L14.55 16.975C14.4667 17.0417 14.375 17.0833 14.275 17.1C14.175 17.1167 14.0833 17.125 14 17.125C13.7333 17.125 13.5 17.0333 13.3 16.85C13.1 16.6667 13 16.425 13 16.125Z" fill="currentColor"/>
                    </svg>
                    `,
                    page: "Boost"
                },
                {
                    name: "Airdrop",
                    icon: `
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5 20C13.2667 20 11.375 19.225 9.825 17.675C8.275 16.125 7.5 14.2333 7.5 12C7.5 9.76667 8.275 7.875 9.825 6.325C11.375 4.775 13.2667 4 15.5 4C17.7333 4 19.625 4.775 21.175 6.325C22.725 7.875 23.5 9.76667 23.5 12C23.5 14.2333 22.725 16.125 21.175 17.675C19.625 19.225 17.7333 20 15.5 20ZM6.125 19.25C4.725 18.6 3.60417 17.625 2.7625 16.325C1.92083 15.025 1.5 13.5833 1.5 12C1.5 10.4167 1.92083 8.975 2.7625 7.675C3.60417 6.375 4.725 5.4 6.125 4.75C6.475 4.58333 6.79167 4.59583 7.075 4.7875C7.35833 4.97917 7.5 5.29167 7.5 5.725C7.5 5.89167 7.44583 6.05417 7.3375 6.2125C7.22917 6.37083 7.09167 6.49167 6.925 6.575C5.875 7.05833 5.04167 7.7875 4.425 8.7625C3.80833 9.7375 3.5 10.8167 3.5 12C3.5 13.1833 3.80833 14.2625 4.425 15.2375C5.04167 16.2125 5.875 16.9417 6.925 17.425C7.09167 17.5083 7.22917 17.625 7.3375 17.775C7.44583 17.925 7.5 18.0917 7.5 18.275C7.5 18.6917 7.35833 19 7.075 19.2C6.79167 19.4 6.475 19.4167 6.125 19.25Z" fill="currentColor"/>
                    </svg>
                    `,
                    page: "Airdrop"
                },
            ]
        };
    }
}
</script>

<style scoped>
.navbar {
    bottom: 30px;
    position: fixed;

    width: 100%;
    height: 52px;
    background: #000000;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.navbar-container {
    display: flex;
    gap: 40px;
    flex-direction: row;
}

.navbar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;

    font-weight: 600;
    font-size: 11px;
    color: #3a3a3a;
}

.navbar-icon {
    height: 24px;
    fill: #fff;
}

.navbar-item.active {
    fill: #fff;
    color: #fff;
}
.navbar-item.active .navbar-icon {
    fill: #fff;
}
</style>