<template>
    <div id="app">
        <LoadingScreen v-if="isLoading"></LoadingScreen>
        <Navbar v-if="!isLoading">></Navbar>
        <router-view />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <link rel="stylesheet" href="./font/stylesheet.css">
    </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import LoadingScreen from "@/components/LoadingScreen.vue"

export default {
    components: {LoadingScreen, Navbar },
    data() {
        return {
            isLoading: true,
        }
    },
    mounted() {
        setTimeout(() => {
            this.isLoading = false
        }, 1500)
    }
}

</script>

<style>
html,
body {
    height: 100%;
    overflow: hidden;
    touch-action: manipulation;
    user-select: none;

    position: relative;

    background: #FCFDFF;
    color: #fff;
    font-style: normal;

    font-family: 'TT Interfaces';

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    font-weight: 600;

    margin: 0;
}

/* Основной стиль для всего скроллбара */
::-webkit-scrollbar {
    width: 8px; /* Для вертикального скроллбара */
    height: 8px; /* Для горизонтального скроллбара */
}

/* Стилизация дорожки скроллбара */
::-webkit-scrollbar-track {
    background: #000; /* Цвет дорожки */
    border-radius: 10px; /* Закругление дорожки */
}

/* Стилизация ползунка */
::-webkit-scrollbar-thumb {
    background-color: rgba(217, 217, 217, 0.12); /* Цвет ползунка */
    border-radius: 10px; /* Закругление ползунка */
    border: 2px solid #000; /* Обводка, создающая "отступ" внутри дорожки */
}

/* Можно также задать стиль при наведении */
::-webkit-scrollbar-thumb:hover {
    background-color: #ccc; /* Цвет при наведении */
}
</style>
