import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

window.__ = function (phrase) {
    return phrase
}

const app = createApp(App)

app.config.globalProperties.__ = window.__
app.use(store).use(router).mount('#app')
