<script>
export default {
    name: "LoadingScreen"
}
</script>

<template>
    <div class="loading-screen">
        <img class="logo" src="@/assets/logo.svg" alt="Logo" />
    </div>
</template>

<style scoped>
.loading-screen {
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 100vh;
    background-color: #000000;
    background-repeat: no-repeat;
    background-size: 100vh;
    background-position: center;
    background-image: url(../assets/images/loadingScreenBg.png);
}

.logo {
    width: 130px;
    height: 130px;
    animation: pulse 1.5s ease-in-out infinite, fade 1.5s ease-in-out infinite;
}

/* Вращение */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Пульсация (увеличение/уменьшение) */
@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}

/* Постепенное появление и исчезновение */
@keyframes fade {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}
</style>