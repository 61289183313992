<script>
export default {
    name: "Frens"
}
</script>

<template>
<section class="frens">
    <section class="about_frens">
        <div class="title">Frens invited</div>
        <div class="description">You will get 5% income from each referral and he is 250 $PIXY. Rewards twice as much if he is a Premium user</div>
    </section>
</section>
</template>

<style scoped>
    .frens {
        background: #000;
        width: 100%;
        height: 100vh;
    }

    .title {
        font-weight: 700;
        color: #fff;
    }

    .description {
        font-weight: 500;
        color: #5f5f5f;
    }


@media (min-width: 316px) {
    .title {
        color: #fff;
    }

    .description {
        font-size: 15px;
        line-height: 134%;
    }

}
</style>