import {createRouter, createWebHistory} from 'vue-router'
import HomePage from '@/pages/Home.vue'
import WelcomePage from '@/pages/Welcome.vue'
import AirdropPage from '@/pages/Airdrop.vue'
import BoostPage from '@/pages/Boost.vue'
import FrensPage from '@/pages/Frens.vue'
import TasksPage from '@/pages/Tasks.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage
    },
    {
        path: '/welcome',
        name: 'Welcome',
        component: WelcomePage
    },
    {
        path: '/airdrop',
        name: 'Airdrop',
        component: AirdropPage
    },
    {
        path: '/boost',
        name: 'Boost',
        component: BoostPage
    },
    {
        path: '/frens',
        name: 'Frens',
        component: FrensPage
    },
    {
        path: '/tasks',
        name: 'Tasks',
        component: TasksPage
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
