<template>
</template>

<script>

import {defineComponent} from "vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import Navbar from "@/components/Navbar.vue";

export default defineComponent({
    name: "Welcome",
    components: {Navbar, LoadingScreen}
})
</script>

<style>

</style>